import React,{useEffect} from 'react'

import Layout from '../Layout';

export default function Ai() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return(
  <Layout>
    <div className='content1'>
    <section class="hero-service-wrap hero-section-wrap hero-overview-wrap">
            <div class="hero-section-content-wrap">
                <div class="custom-container">
                    <div class="hero-portfolio-body">
                        <div class="hero-section-content text-center">
                            <h5 class="section-subtitle">Artificial intelligence & Machine Learning</h5>
                            <h1 class="section-title fade-in">Development is the pillar of
                                our company</h1>
                            <p>Artificial Intelligence and Machine Learning are two emerging trends 
                                in technologies. Lakshya caters to the prominent AI and ML to deliver high-end solutions.
                                We provide intelligent and data-driven insights that help your AI vision convert into reality. Our company helps your business enhance and provide Image & Video, Text To Speech, Business Intelligence, Data Forecasting, Natural Language Processing, and Data Analytics. These tools enable you to operate with a large amount of data and valuable insight into the present and future predictions. The understanding will help you to stay ahead and gain a significant edge in competition. The data can be of institutional knowledge, internal, industry, and more. Artificial Intelligence
                                 and Machine are easily affordable and accessible, but you require a developer to manage all.
                        </p><br></br>
                        <p>
Machine learning is a part of Artificial Intelligence and is considered the most evolving technology for business. With real-world insight, AI & ML help to build frameworks that support businesses to gain profit. The organization that leverages Machine Learning and Artificial Intelligence has witnessed the transformation in every aspect of the company. It has helped them to reshape multiple industries and support enterprises with strategic management. The implementation of the project becomes easier with these tools and research analytics.
</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="new-release-hero-img-area">
            <div class="custom-container">
                <div class="new-release-img-box">
                    <img src="./assets/imgs/service-details-1.jpg" alt="Service Details"/>
                </div>
            </div>
        </section>


        
        <section class="about-service7-area">
            <div class="custom-container">
                <div class="about-service7-body d-flex align-items-center">
                    <div class="about-service7-content">
                        <div class="section-header">
                            <h5 class="section-subtitle">SOFTWARE DEVELOPMENT</h5>
                            <h1 class="section-title">Engine of progress</h1>
                            <p>Development as stone of innovation.</p>
                        </div>
                        <p>Software development is the stab process of designing, programming, solids to testing, and maintaining software as the applications and systems. It empowers us to turn ideas</p>
                        <p>Software developers, often rest referred to as coders or programmers, are sat the creative minds behind the digital innovations we use daily. They possess a unique blend of</p>
                        <p>Developers write lines of code by state using programming languages such as Python, Java, JavaScript, and C++, </p>
                    </div>
                    <div class="about-service7-img-box d-flex">
                        <img src="./assets/imgs/service-details-2.jpg" alt="Service Details"/>
                        <img src="./assets/imgs/service-details-3.jpg" alt="Service Details"/>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="about-service3-area about-service3-style-2">
            <img src="./assets/imgs/bg-shape-3.svg" alt="Shape" class="animation-slide-right bg-shape"/>
            <div class="custom-container">
                <div class="custom-row align-items-center">
                    <div class="img-box">
                        <img src="./assets/imgs/about-service-7.jpg" alt="About" />
                    </div>

                    <div class="content-box">
                        <h5 class="section-subtitle">IMPORTANCE</h5>
                        <h1 class="section-title">The Role of Developers</h1>
                        <p>Software developers, often referred to as coders or programmers, are the creative minds behind the digital innovations we use daily.</p>
                        <ul>
                            <li>
                                <i class="las la-check"></i> PPD Development
                            </li>
                            <li>
                                <i class="las la-check"></i> Easy to Use
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="feature3-area feature3-style-2">
            
            <div class="custom-container">
                <div class="feature3-body">
                    <div class="section-header">
                        <h5 class="section-subtitle">FEATURES</h5>
                        <h1 class="section-title">AI & ML Services Include:   
                            </h1>
                    </div>

                    <div class="feature3-lists">
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Recommendation Engine  </h3>
                            <p>One of the efficient services of Artificial Intelligence and Machine learning is the Recommendation Engine. The Engine helps to search and give you personalized suggestions, boost sales, and enhance user experience.
                                 It is frequently used in the e-commerce sector, lifestyle sector, and more. </p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Process Automation  </h3>
                            <p>Process Automation is used for the automation of repetitive tasks. It includes several assignments where it helps to optimize resources, save time and be cost-effective. Thus, process
                                Automation enhances business workflow and increases the execution speed of operations.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Voice-based AI  </h3>
                            <p>Voice-based AI has ruled the market and increased the demand by applying it for several purposes. Siri, Alexa, Google Assistant, and others are famous examples of Voice-based personal Artificial Intelligent Assistants. We provide personalized assistants and customize them to engage the customers. 
                                Moreover, we ensure it to make it more dynamic.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Chatbots </h3>
                            <p>Another useful service of Artificial Intelligence and Machine learning is Chatbots. AI-enabled Chatbots enable to have a conversation through bots and make humans interact with the machine more engaging. The Chatbots help to solve the query and respond 24/7. 
                                It is easy to function and very useful for the business.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Inventory Optimization   </h3>
                            <p>Inventory Optimization helps to optimize and streamline productions. Artificial Intelligence allows businesses to figure out sales and overall consumption of the product. It helps to predict forecasts and improve the method of the supply chain. We help to create business strategies and improve the functions of businesses.
                                 Our team assures us to solve complex problems and enhance business productivity.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Image Processing   </h3>
                            <p>Artificial Intelligence and Machine learning are used to recognize the image, object, or any other images. Image processing helps identify a particular thing, scale pictures, remember the image's pattern, and more. It is frequently used in the medical sector, security industry, and more. We present image processing services that provide accuracy. Our team offers exceptional
                                 image processing service that helps you to increase the growth of the business.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Object Recognition  </h3>
                            <p>Object recognition is applied to identify appropriate objects from images and obtain valuable information for additional use. Therefore, it needs expanded use of Artificial Intelligence, Machine Learning and Neural networks. Smart search engines, identifying particular things, human image and video, distinguishing text in pictures, and more are object recognition applications. Object recognition makes human work more effortless.</p>
                        </div>
                     
                    </div>
                </div>
            </div>
        </section>




        <section class="testimonial-area">
            <div class="custom-container">
                
                <div class="testimonial-slider-wrap">
                    <div class="testimonial-slider swiper">
                        
                        <div class="swiper-wrapper">
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="animation-slide-right bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Platform helped me to increase my sales and revenue
                                        generated by Lakshya Software Technologies</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-1.jpg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Serana Belluci</h4>
                                            <p>Product Designer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Lakshya give the best service and top customer
                                        support. Really like to have more projects with you</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-2.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Megan Clarke</h4>
                                            <p>CEO, Sanlab</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>We’ve been using Lakshya for a few years, It is very 
                                        simple and easy to approach team and management</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-3.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Rachel Olsem</h4>
                                            <p>Full Stack Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    
                    
                    <div class="swiper-button-prev"><i class="las la-arrow-left"></i></div>
                    <div class="swiper-button-next"><i class="las la-arrow-right"></i></div>
                </div>

            </div>
        </section>
    </div>
    </Layout>
  )
}
