import React from 'react'
import Layout from '../Layout';

export default function Industries() {
  return (
    <Layout>
    <div className='content1'>Industries</div>
    </Layout>
  )
}
