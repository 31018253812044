import React from 'react'
import Layout from '../Layout';

export default function Hotels() {
  return (
    <Layout>
    <div className='content1'>Hotels</div>
    </Layout>
  )
}
