import React, { useEffect } from 'react';

import Layout from '../Layout';


export default function Artificial() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return(
  <Layout>
   <div className='content1'>

   <section class="hero-service-wrap hero-section-wrap hero-overview-wrap">
    <div class="hero-section-content-wrap">
        <div class="custom-container">
            <div class="hero-portfolio-body">
                <div class="hero-section-content text-center">
                    <h5 class="section-subtitle">Cognitive Computing and Data Insights</h5>
                    <h1 class="section-title fade-in">Artificial Intelligence & Advanced Analytics</h1>
                    <p>Our expertise in artificial intelligence, including generative AI (GenAI), computer vision, and natural language processing (NLP), is transforming the semiconductor landscape. We deploy advanced analytics to drive innovation, streamline design processes, and enable next-generation advancements. Whether it’s optimizing chip architecture or improving predictive maintenance, 
                        our AI-powered solutions ensure precision and scalability in every project.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature3-area feature3-style-2">
    
    <div class="custom-container">
        <div class="feature3-body">
            <div class="section-header">
                <h5 class="section-subtitle">core solutions</h5>
                <h1 class="section-title">AI Solutions for Business Optimization
                    </h1>
            </div>

            <div class="feature3-lists">
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>AI Solutions for Business Optimization</h3>
                    <p>At Lakshya Software Technologies, we offer cutting-edge AI (Artificial Intelligence) solutions designed to optimize business operations and drive innovation. By leveraging machine learning, deep learning, natural language processing (NLP), and computer vision, we create intelligent systems 
                        that enhance decision-making, improve efficiency, and automate complex processes.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Advanced Analytics for Data-Driven Decision Making</h3>
                    <p>At Lakshya Software Technologies, our advanced analytics services empower organizations to unlock the full potential of their data, turning raw information into actionable insights. By applying sophisticated data mining, statistical analysis, and predictive modeling techniques, we help businesses make informed, 
                        data-driven decisions that enhance operational efficiency and drive growth.</p>
                </div>
              
               
           
             
              
             
               
            </div>
            
        </div>
    </div>
</section>














</div>
    
 
 </Layout>
  )
}

