import React,{useEffect} from 'react'
import Layout from '../Layout';


export default function Bpm() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return(
  <Layout>

    <div  className='content1'>
    <section class="hero-service-wrap hero-section-wrap hero-overview-wrap">
            <div class="hero-section-content-wrap">
                <div class="custom-container">
                    <div class="hero-portfolio-body">
                        <div class="hero-section-content text-center">
                            <h5 class="section-subtitle">ERP implementation</h5>
                            <h1 class="section-title fade-in">Development is the pillar of
                                our company</h1>
                            <p>
                            As a leading provider of ERP solutions, we offer a comprehensive suite of
                             services designed to address the diverse needs of modern businesses. From selection and implementation to customization and support, we partner with our clients to deliver tailored ERP solutions that streamline operations, optimize processes, and unlock new opportunities for success. With a focus on industry-leading platforms such as SAP, Oracle, and Microsoft Dynamics, we empower organizations to harness the full potential of ERP technology to achieve their 
                            trategic objectives and drive sustainable growth.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="new-release-hero-img-area">
            <div class="custom-container">
                <div class="new-release-img-box">
                    <img src="./assets/imgs/service-details-1.jpg" alt="Service Details"/>
                </div>
            </div>
        </section>


        
        <section class="about-service7-area">
            <div class="custom-container">
                <div class="about-service7-body d-flex align-items-center">
                    <div class="about-service7-content">
                        <div class="section-header">
                            <h5 class="section-subtitle">SOFTWARE DEVELOPMENT</h5>
                            <h1 class="section-title">Engine of progress</h1>
                            <p>Development as stone of innovation.</p>
                        </div>
                        <p>Software development is the stab process of designing, programming, solids to testing, and maintaining software as the applications and systems. It empowers us to turn ideas</p>
                        <p>Software developers, often rest referred to as coders or programmers, are sat the creative minds behind the digital innovations we use daily. They possess a unique blend of</p>
                        <p>Developers write lines of code by state using programming languages such as Python, Java, JavaScript, and C++, </p>
                    </div>
                    <div class="about-service7-img-box d-flex">
                        <img src="./assets/imgs/service-details-2.jpg" alt="Service Details"/>
                        <img src="./assets/imgs/service-details-3.jpg" alt="Service Details"/>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="about-service3-area about-service3-style-2">
            <img src="./assets/imgs/bg-shape-3.svg" alt="Shape" class="animation-slide-right bg-shape"/>
            <div class="custom-container">
                <div class="custom-row align-items-center">
                    <div class="img-box">
                        <img src="./assets/imgs/about-service-7.jpg" alt="About" />
                    </div>

                    <div class="content-box">
                        <h5 class="section-subtitle">IMPORTANCE</h5>
                        <h1 class="section-title">The Role of Developers</h1>
                        <p>Software developers, often referred to as coders or programmers, are the creative minds behind the digital innovations we use daily.</p>
                        <ul>
                            <li>
                                <i class="las la-check"></i> PPD Development
                            </li>
                            <li>
                                <i class="las la-check"></i> Easy to Use
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="feature3-area feature3-style-2">
            
            <div class="custom-container">
                <div class="feature3-body">
                    <div class="section-header">
                        <h5 class="section-subtitle">FEATURES</h5>
                        <h1 class="section-title">Key Stages in ERP implementation
                            </h1>
                    </div>

                    <div class="feature3-lists">
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Custom ERP Development:</h3>
                            <p>Tailor-made solutions for companies with unique business processes and regulatory requirements. Our custom ERP development services ensure your system perfectly aligns with your organization's needs, 
                                enhancing productivity and compliance. </p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Platform-Based ERP Development:</h3>
                            <p>Harness the power of out-of-the-box functionality with our platform-based ERP development services. We identify areas where off-the-shelf ERP systems fall short of your requirements and customize only those aspects, minimizing your investment while maximizing functionality. With Lakshya, you'll get a solution that meets your needs without 
                                breaking the bank.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>ERP Selection Consulting:</h3>
                            <p>Ensure your company's software ecosystem aligns with your strategic goals and processes with Lakshya's ERP selection consulting services. Say goodbye to the buy vs. build dilemma as our experienced consultants guide you through the decision-making process. Whether you opt for a custom solution or a platform-based one with minimal customization, we consider key factors such as total cost of ownership (TCO), feature set, user experience (UX), customizability, scalability, integration capabilities, security, industry-specific fit, uptime and performance, and tech support availability. With Lakshya, 
                                you'll make an informed choice that maximizes efficiency and drives business growth.</p>
                        </div>
                        
                      
                        
                    </div>
                </div>
            </div>
        </section>
        
        




        <section class="testimonial-area">
            <div class="custom-container">
                
                <div class="testimonial-slider-wrap">
                    <div class="testimonial-slider swiper">
                        
                        <div class="swiper-wrapper">
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="animation-slide-right bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Platform helped me to increase my sales and revenue
                                        generated by Lakshya Software Technologies</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-1.jpg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Serana Belluci</h4>
                                            <p>Product Designer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Lakshya give the best service and top customer
                                        support. Really like to have more projects with you</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-2.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Megan Clarke</h4>
                                            <p>CEO, Sanlab</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>We’ve been using Lakshya for a few years, It is very 
                                        simple and easy to approach team and management</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-3.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Rachel Olsem</h4>
                                            <p>Full Stack Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    
                    
                    <div class="swiper-button-prev"><i class="las la-arrow-left"></i></div>
                    <div class="swiper-button-next"><i class="las la-arrow-right"></i></div>
                </div>

            </div>
        </section>
    </div>
   </Layout>
  )
}
