
import './App.css';
import Header from './common/Header';
import Home from './Home';
import About from './Aboutus'
import Footer from './common/Footer';
import Contact from './contact';
import Carrers from './Carrers';
import Bpm from './Services/bpm';
import Crm from './Services/crm';
import Csd from './Services/csd';
import Erp from './Services/Erp';
import Cs from './Services/Cs';
import Ai from './Services/Ai';

import Psm from './Services/psm';
import Sa from './Services/sa';

import brainstorming from './Services/Brainstorming'
import frontend from './Services/Frontend';
import seo from './Services/Seo';
import product from './Services/Product';
import psm from './Services/psm';
import Epm from  './solutions/Epm';
import As from  './solutions/As';
import Es from  './solutions/Es';
import Artificial from  './solutions/Artificial';


import Hcm from  './solutions/Hcm';
import Banks from './fields/Banks';
import Healthcare from './fields/Healthcare';
import Hotels from './fields/Hotels';
import Industries from './fields/Industries';
import Logistics from './fields/Logistics';
import Supermarkets from './fields/Supermarkets';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Aboutus from './Aboutus';
import Brainstorming from './Services/Brainstorming';
import Seo  from './Services/Seo';
import Frontend from './Services/Frontend';
import Product from './Services/Product';
import Specialized from './Services/Specialized';
import Verification from './Services/Verification';

import Systemdesign from './solutions/Systemdesign';
import Vlsi from './solutions/Vlsi';
import Iot from './solutions/Iot';

function App() {
  return (
    <div>
      <Router>
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contact" element={<Contact us/>} />
          <Route path="/as" element={<As/>} />
          <Route path="/es" element={<Es/>} />
      
          <Route path="/cs" element={<Cs />}/>
          <Route path="/carrers" element={<Carrers />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/ai" element={<Ai />} />
          <Route path="/bpm" element={<Bpm />} />
          <Route path="/crm" element={<Crm />} />
          <Route path="/csd" element={< Csd />} />
          <Route path="/erp" element={<Erp/>} />
          <Route path="/psm" element={<Psm />} />
          <Route path="/epm" element={<Epm />} />
          <Route path="/hcm" element={<Hcm/>} />
          <Route path="/sa" element={<Sa/>} />
          
          <Route path="/Banks" element={<Banks/>} />
          <Route path="/healthcare" element={<Healthcare/>} />
          <Route path="/hotels" element={<Hotels/>} />
          <Route path="/industries" element={<Industries/>} />
          <Route path="/logistics" element={<Logistics/>} />
          <Route path="/supermarkets" element={<Supermarkets/>} />
          <Route path="/brainstorming" element={<Brainstorming/>} />
          <Route path="/seo" element={<Seo/>} />
          <Route path="/frontend" element={<Frontend/>} />
          <Route path="/product" element={<Product/>} />
          <Route path="/artificial" element={<Artificial/>} />
         
          
          <Route path="/systemdesign" element={<Systemdesign/>} />
          <Route path="/vlsi" element={<Vlsi/>} />
          <Route path="/iot" element={<Iot/>} />
          <Route path="/specialized" element={<Specialized/>} />
          <Route path="/verification" element={<Verification/>} />
          
         
        </Routes>
    
    </Router>
</div>
    
       
  );
}

export default App;
