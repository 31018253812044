import React from 'react'
import Layout from '../Layout';

export default function Banks() {
  return (
    <Layout>
    <div className='content1'>Banks</div>
    </Layout>
  )
}
