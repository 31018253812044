import React, { useEffect } from 'react';
import Layout from './Layout';
import './Home.css';
import Contact from './contact';
import { Link } from 'react-router-dom';

const Home = () => {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
    return (
        <Layout>
            <section class="hero-empowerment-area content2">
                <div class="custom-container">
                    <div class="custom-row align-items-center">
                        <div class="hero-empowerment-left-content">
                            <h5 class="section-subtitle">EMPOWERMENT</h5>
                            <h1 class="section-title fade-in">From concept to code Lakshya leads the way</h1>
                            <p>Lakshya Software is a forward-thinking technology company committed to delivering
                                cutting-edge digital solutions tailored to meet the evolving needs of businesses.
                                Founded on the principles of innovation and precision, Lakshya Software specializes in crafting customized software applications,
                                from enterprise solutions to mobile and web development.</p>
                            <div class="btns-group d-flex">
                                <Link to="aboutus">
                                    <a class="theme-btn">Learn More</a></Link>
                                <Link to="/contact" >
                                    <a class="theme-btn2">Let's Talk
                                        <i class="iconoir-arrow-up-right"></i>
                                    </a></Link>
                            </div>
                        </div>

                        <div class="hero-empowerment-right-content">
                            <div class="top-content">
                                <img class="desktop fade-in" src="./assets/imgs/bg1-1.png" alt="Empowerment" />
                                <img class="mobile" src="./assets/imgs/bg1.png" alt="Empowerment" />

                                <div class="experience-box simple-shadow bounce-in">
                                    <div class="experience-body d-flex align-items-center">
                                        <img src="./assets/imgs/icon1.svg" alt="Icon" />

                                        <div class="experience-content d-flex align-items-center">
                                            <h1>+9</h1>
                                            <p>
                                                Years
                                                <span>Experience</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="bottom-content d-flex">
                                <a href="./team.html" class="our-expert-team-box simple-shadow bounce-in delay-2">
                                    <div class="our-expert-team-box-inner d-flex align-items-center">
                                        <div class="imgs imgs1 d-flex align-items-center">
                                            <img src="./assets/imgs/small-img-4.png" alt="team" />
                                            <img src="./assets/imgs/small-img-3.png" alt="team" />
                                            <img src="./assets/imgs/small-img-2.png" alt="team" />
                                            <img src="./assets/imgs/small-img-1.png" alt="team" />
                                        </div>
                                        <p>
                                            Meet
                                            <span>&nbsp;Our Experts</span>
                                        </p>
                                    </div>
                                </a>

                                <div class="google-reviews-box simple-shadow bounce-in delay-3">
                                    <div class="left">
                                        <span>Verified by</span>
                                        <img src="./assets/imgs/icon2.svg" alt="Google" />
                                    </div>
                                    <div class="right">
                                        <div class="stars d-flex align-items-center">
                                            <i class="las la-star"></i>
                                            <i class="las la-star"></i>
                                            <i class="las la-star"></i>
                                            <i class="las la-star"></i>
                                            <i class="las la-star"></i>
                                        </div>
                                        <p>
                                            45
                                            <span>Reviews</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
            <section className="client-area">
                <div>
                    <h2 class="section-title1">Our Clients</h2>
                </div>
                <div class="clients clients-marquee d-flex align-items-center ">
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/AMD-Symbol.png" alt="Client" />
                    </div>
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/Digital-Nirvana-Logo.jpg" />
                    </div>
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/Logo-Atos.jpg" alt="Client" />
                    </div>
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/metso_logo_0.png" alt="Client" />
                    </div>
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/OIP 1.jpg" alt="Client" />
                    </div>
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/OIP.jpg" alt="Client" />
                    </div>
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/qualcomm-logo-3.png" alt="Client" />
                    </div>
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/R 1.jpg" alt="Client" />
                    </div>
                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/R.png" alt="Client" />
                    </div>

                    <div class="client-logo simple-shadow">
                        <img src="./assets/imgs/clients/Synopsys-logo.png" alt="Client" />
                    </div>
                    <div class="client-logo"></div>
                </div>
            </section>

            <section class="how-we-do-area">
                <div class="custom-container">
                    <div class="custom-row">
                        <img src="./assets/imgs/bg-shape-1.svg" alt="Shape" class="animation-slide-left how-we-do-bg" />
                        <div class="how-we-do-left-content">
                            <div class="top">
                                <h5 class="section-subtitle">Our Model</h5>
                                <h1 class="section-title">How we do</h1>
                                <p>Save time and money with our powerful method.</p>
                            </div>
                            {/* <a href="how-we-do.html" class="theme-btn">
                                Learn More
                                <i class="iconoir-arrow-up-right"></i>
                            </a> */}
                        </div>
                        <div class="how-we-do-right-content">
                            <div class="how-we-do-items d-flex align-items-center justify-content-center">
                                <div class="how-we-do-card">
                                    <div class="circle-shape"></div>
                                    <div class="line-shape"></div>
                                    <div class="how-we-do-icon">
                                        <img src="./assets/imgs/requirement.svg" alt="How we do" />
                                    </div>
                                    <div class="how-we-do-content">
                                        <h4>Requirement Analysis</h4>
                                        <p>Analyze</p>
                                    </div>
                                </div>
                                <div class="how-we-do-card">
                                    <div class="circle-shape"></div>
                                    <div class="line-shape"></div>
                                    <div class="how-we-do-icon">
                                        <img src="./assets/imgs/Calendar.svg" alt="How we do" />
                                    </div>
                                    <div class="how-we-do-content">
                                        <h4>Planning & Strategy</h4>
                                        <p>Plan</p>
                                    </div>
                                </div>
                                <div class="how-we-do-card">
                                    <div class="circle-shape"></div>
                                    <div class="line-shape"></div>
                                    <div class="how-we-do-icon">
                                        <img src="./assets/imgs/design-pencil.svg" alt="How we do" />
                                    </div>
                                    <div class="how-we-do-content">
                                        <h4>Design & Prototyping</h4>
                                        <p>Design</p>
                                    </div>
                                </div>
                            </div>
                            <div class="how-we-do-items d-flex align-items-center justify-content-center">
                                <div class="how-we-do-card">
                                    <div class="circle-shape"></div>
                                    <div class="line-shape"></div>

                                    <div class="how-we-do-icon">
                                        <img src="./assets/imgs/tools.svg" alt="How we do" />
                                    </div>
                                    <div class="how-we-do-content">
                                        <h4>Testing & Quality Assurance</h4>
                                        <p>Test</p>
                                    </div>
                                </div>
                                <div class="how-we-do-card">
                                    <div class="circle-shape"></div>
                                    <div class="line-shape"></div>

                                    <div class="how-we-do-icon">
                                        <img src="./assets/imgs/cloud-sync.svg" alt="How we do" />
                                    </div>
                                    <div class="how-we-do-content">
                                    <h4>Deployment</h4>
                                    <p>Deploy</p>
                                      
                                    </div>
                                </div>
                                <div class="how-we-do-card">
                                    <div class="circle-shape"></div>
                                    <div class="line-shape"></div>
                                    <div class="how-we-do-icon">
                                        <img src="./assets/imgs/code-brackets-square.svg" alt="How we do" />
                                    </div>
                                    <div class="how-we-do-content">
                                    <h4>Development</h4>
                                    <p>Build</p>
                                    </div>
                                </div>
                            </div>
                            <div class="how-we-do-items d-flex ">
                                <div class="how-we-do-card">
                                    <div class="circle-shape"></div>
                                    <div class="how-we-do-icon">
                                        <img src="./assets/imgs/headset-help.svg" alt="How we do" />
                                    </div>
                                    <div class="how-we-do-content">
                                        <h4>Support & Maintenance</h4>
                                        <p>Support</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="case-studio-area">
                <div class="custom-container">
                    <div class="case-studio-header text-center">
                        <h5 class="section-subtitle">OUR EXPERTISE</h5>
                        <h1 class="section-title">Detailing of our products</h1>
                    </div>
                    <div class="case-studio">
                        <ul class="nav nav-pills case-studio-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <button class="nav-link active" id="development-tab" data-bs-toggle="tab"
                                    href="#development" role="tab" aria-controls="development" aria-selected="true">Services</button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" id="woo_commerce-tab" data-bs-toggle="tab" href="#woo_commerce"
                                    role="tab" aria-controls="woo_commerce" aria-selected="false">Solutions</button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" id="crm_solutions-tab" data-bs-toggle="tab" href="#crm_solutions"
                                    role="tab" aria-controls="crm_solutions" aria-selected="false">AI & ML</button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" id="web_designing-tab" data-bs-toggle="tab" href="#web_designing"
                                    role="tab" aria-controls="web_designing" aria-selected="false">App Development</button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" id="it_support-tab" data-bs-toggle="tab" href="#it_support"
                                    role="tab" aria-controls="it_support" aria-selected="false">Cloud Services</button>
                            </li>
                        </ul>
                        <div class="tab-content case-studio-tab-content">
                            <div class="tab-pane fade show active" id="development" role="tabpanel" aria-labelledby="development-tab">
                                <div class="case-studio-body d-flex">
                                    <div class="left d-flex flex-1">
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">ERP/CRM</a>
                                            <img src="/assets/imgs/ourexpertise/services-1.jpg" alt="Case Studio" />
                                        </div>
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">Technologies</a>
                                            <img src="/assets/imgs/ourexpertise/services-2.jpg" alt="Case Studio" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div class="case-studio-contents service-card card-h">
                                            <img src="./assets/imgs/service-icon-1.svg" alt="ICON" />
                                            <h3>Services</h3>
                                            <p>
                                            We offer tailored solutions including software development, ERP/CRM implementation, cloud services, AI/ML, HCM, BPM, EPM, supply chain optimization, technical consultancy, and system assurance. Our expertise ensures innovation, efficiency, and seamless integration.</p>
                                            <a href="#" class="theme-btn">
                                                <i class="iconoir-arrow-up-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="woo_commerce" role="tabpanel" aria-labelledby="woo_commerce-tab">
                                <div class="case-studio-body d-flex">
                                    <div class="left d-flex flex-1">
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">Transformative Technology</a>
                                            <img src="./assets/imgs/ourexpertise/solution-1.jpg" alt="Case Studio" />
                                        </div>
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">Smart Analytics</a>
                                            <img src="./assets/imgs/ourexpertise/solution-2.jpg" alt="Case Studio" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div class="case-studio-contents service-card card-h">
                                            <img src="./assets/imgs/service-icon-2.svg" alt="ICON" />
                                            <h3>Solutions</h3>
                                            <p>
                                                We provide specializing in custom software development, enterprise solutions, and digital transformation. With expertise in creating scalable applications,
                                                we empower businesses to optimize their processes through cutting-edge technology.
                                            </p>
                                            <a href="#" class="theme-btn">
                                                <i class="iconoir-arrow-up-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="crm_solutions" role="tabpanel" aria-labelledby="crm_solutions-tab">
                                <div class="case-studio-body d-flex">
                                    <div class="left d-flex flex-1">
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">Artificial Intelligence</a>
                                            <img src="./assets/imgs/ourexpertise/ai-1.jpg" alt="Case Studio" />
                                        </div>
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">Machine Learning</a>
                                            <img src="./assets/imgs/ourexpertise/ai-2.jpg" alt="Case Studio" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div class="case-studio-contents service-card card-h">
                                            <img src="./assets/imgs/service-icon-3.svg" alt="ICON" />
                                            <h3>AI & ML</h3>
                                            <p>Lakshya Software offers advanced AI (Artificial Intelligence) and Machine Learning (ML)
                                                Solutions, empowering businesses to leverage data for smarter decision-making and automation.
                                                Our AI/ML services include predictive analytics, natural language processing (NLP), computer vision, and custom algorithm development.</p>
                                            <a href="#" class="theme-btn">
                                                <i class="iconoir-arrow-up-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="web_designing" role="tabpanel" aria-labelledby="web_designing-tab">
                                <div class="case-studio-body d-flex">
                                    <div class="left d-flex flex-1">
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">Web Designing</a>
                                            <img src="./assets/imgs/case-studio-7.jpg" alt="Case Studio" />
                                        </div>
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">Mobile Development</a>
                                            <img src="./assets/imgs/case-studio-8.jpg" alt="Case Studio" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div class="case-studio-contents service-card card-h">
                                            <img src="./assets/imgs/service-icon-4.svg" alt="ICON" />
                                            <h3>App Development</h3>
                                            <p>Lakshya Software Technologies is a dynamic IT solutions provider specializing in innovative website and mobile app development. We empower businesses with cutting-edge technology, delivering responsive designs, user-friendly interfaces, and feature-rich applications. Our expertise lies in crafting tailored digital solutions that align with your vision, ensuring
                                                seamless functionality and exceptional performance to drive your success in the digital era.</p>
                                            <a href="#" class="theme-btn">
                                                <i class="iconoir-arrow-up-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="it_support" role="tabpanel" aria-labelledby="it_support-tab">
                                <div class="case-studio-body d-flex">
                                    <div class="left d-flex flex-1">
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">Cloud</a>
                                            <img src="./assets/imgs/case-studio-5.jpg" alt="Case Studio" />
                                        </div>
                                        <div class="case-studio-img-card simple-shadow">
                                            <a href="#" class="case-studio-cat">CRM</a>
                                            <img src="./assets/imgs/case-studio-6.jpg" alt="Case Studio" />
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div class="case-studio-contents service-card card-h">
                                            <img src="./assets/imgs/hwd-icon-4.svg" alt="ICON" />
                                            <h3>Cloud Services</h3>
                                            <p>
                                                Lakshya Software offers comprehensive Cloud Services, including seamless cloud migration,
                                                infrastructure management, and cloud-native application development.
                                                Our team ensures businesses leverage the scalability, security, and flexibility of cloud platforms like AWS, Azure, and Google Cloud. We also provide cloud optimization and
                                                disaster recovery solutions to maximize performance and ensure business continuity.
                                            </p>
                                            <a href="#" class="theme-btn">
                                                <i class="iconoir-arrow-up-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           <section class="about-area">
                <div class="custom-container">
                    <div class="custom-row justify-content-between align-items-center">
                        <div class="left-content">
                            <h5 class="section-subtitle">CONSULTING EXCELLENCE</h5>
                            <h1 class="section-title">Best pathway to our clients.</h1>
                            <p>Our consulting process begins with a thorough assessment of your current IT infrastructure, workflows, and pain points.</p>
                            <ul>
                                <li>
                                    <i class="icon-check las la-check"></i> 24/7 Full Service Support
                                </li>
                                <li>
                                    <i class="icon-check las la-check"></i> Immediate Response
                                </li>
                                <li>
                                    <i class="icon-check las la-check"></i> Easy to Approach us
                                </li>
                            </ul>
                        </div>

                        <div class="right-content">
                            <div class="about-timeline">

                                <div class="about-timeline-item">
                                    <div class="about-timeline-item-inner">
                                        <img src="./assets/imgs/bg-shape-2.svg" alt="Shape" class="line-shape" />
                                        <span class="number">01</span>
                                        <h3>Discovery and Analysis</h3>
                                        <p>Perform a analysis of the client's existing IT systems.</p>
                                    </div>
                                </div>
                                <div class="about-timeline-item">
                                    <div class="about-timeline-item-inner">
                                        <img src="./assets/imgs/bg-shape-2.svg" alt="Shape" class="line-shape" />
                                        <span class="number">02</span>
                                        <h3>Tailored Solutions</h3>
                                        <p>Develop IT solutions based on the analysis phase.</p>
                                    </div>
                                </div>
                                <div class="about-timeline-item">
                                    <div class="about-timeline-item-inner">
                                        <img src="./assets/imgs/bg-shape-2.svg" alt="Shape" class="line-shape" />
                                        <span class="number">03</span>
                                        <h3>Deployment and Support</h3>
                                        <p>Regularly communicate with our client to any concern.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </Layout>
    );
};

export default Home;
