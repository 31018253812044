import React,{useEffect} from 'react'
import Layout from './Layout'
import {Link} from "react-router-dom"
import './Aboutus.css'
export default function Aboutus() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return (
  <Layout>
    <div className='content1'>
    
        <section className="hero-service-wrap hero-section-wrap hero-about-wrap">
            <div className="hero-section-content-wrap">
                <div className="custom-container">
                    <div className="hero-portfolio-body">
                        <div className="hero-section-content text-center">
                            <h5 className="section-subtitle">Company</h5>
                            <h1 className="section-title fade-in">
                            Create meaningful, relevant solutions
                                </h1>
                            <p>Experience the transformative power of innovation and seamless solutions with
                                dynamics. Our journey doesn't end with the delivery of a solution.</p>
                        </div>

                        {/* <div className="hero-company-boxes">
                            <div className="hero-company-box simple-shadow">
                                <h1>300+</h1>
                                <h3>Projects</h3>
                                <p>We are spread around
                                    the world.</p>
                            </div>
                            <div className="hero-company-box simple-shadow">
                                <h1>100%</h1>
                                <h3>Client Satisfaction</h3>
                                <p>Our clients a happy with 
                                    our service.</p>
                            </div>
                            <div className="hero-company-box simple-shadow">
                                <h1>15k</h1>
                                <h3>Legal Customers</h3>
                                <p>Our customers are from
                                    many countries.</p>
                            </div>
                            <div className="hero-company-box simple-shadow">
                                <h1>1995</h1>
                                <h3>We Established On</h3>
                                <p>Our company have a 
                                    great history.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>


        <section className="client-area mt-0">
          <div>
          <h2 className="section-title1">Our Clients</h2>
          </div>
            <div className="clients clients-marquee d-flex align-items-center ">
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/AMD-Symbol.png" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/Digital-Nirvana-Logo.jpg"  />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/Logo-Atos.jpg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/metso_logo_0.png" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/OIP 1.jpg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/OIP.jpg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/qualcomm-logo-3.png" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/R 1.jpg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/R.png" alt="Client" />
                </div>
               
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/clients/Synopsys-logo.png" alt="Client" />
                </div>
                <div className="client-logo"></div>
            </div>
        </section>
         {/* <section className="client-area">
            <div className="clients clients-marquee d-flex align-items-center">
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo1.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo2.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo3.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo4.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo5.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo2.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo3.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo4.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo5.svg" alt="Client" />
                </div>
                <div className="client-logo simple-shadow">
                    <img src="./assets/imgs/client-logo2.svg" alt="Client" />
                </div>
                <div className="client-logo" style={{minWidth: "0"}}></div>
            </div>
        </section>  */}


        
        <section className="company-service-area">
            <div className="custom-container">
                <div className="hero-service-about">
                    <div className="section-header d-flex align-items-center justify-content-between w-full">
                        <div className="left">
                            <h5 className="section-subtitle">OUR COMPANY</h5>
                            <h1 className="section-title">Create meaningful, relevant solutions</h1>
                            <p>Contact us today to begin your journey!</p>
                        </div>
                        <Link to="/contact" class="theme-btn">contact us </Link>
                    </div>
                    

                     <img src ="./assets/imgs/about.jpg" width='100px' alt="About" /> 
                     <div className='row'>
                        <div className='col-lg-6'>
                            <h3>OUR MISSION</h3>
                            <p style={{ color: '#1C1C1CCC' }}>Our mission is to empower businesses through cutting-edge 
                                technology solutions that drive growth, innovation, and success. We are committed to delivering exceptional value to our clients by providing customized, scalable, and reliable 
                                solutions that meet their unique business needs and exceed their expectations.</p>
                        </div>
                        <div className='col-lg-6'>
                        <h3>OUR INTEGRITY</h3>
                            <p style={{ color: '#1C1C1CCC' }}>Integrity is the bedrock of Lakshya We prioritize honesty, transparency, and ethical conduct in all our interactions. Our commitment to integrity guides our decisions, actions, and relationships with clients, partners, and employees. With Lakshya, you can trust that we will always operate with integrity, accountability, and dedication.</p>
                        </div>
                        </div>
                        <div className='row mt-5'>
                        <div className='col-lg-6'>
                            <h3>OUR APPROACH</h3>
                            <p style={{ color: '#1C1C1CCC' }}>We believe in taking a collaborative and customer-centric approach to every project. We work closely with our clients to understand their business objectives, challenges, and requirements, allowing us to develop customized solutions that address their specific needs and deliver measurable results. By leveraging industry best practices, cutting-edge technologies, and a commitment to excellence, we ensure that our clients
                                achieve success and stay ahead in today's competitive landscape.</p>
                        </div>
                    
                     
                        <div className='col-lg-6'>
                            <h3>OUR EXPERTISE </h3>
                            <p style={{ color: '#1C1C1CCC' }}>We specialize in SAP, Oracle, Salesforce, and customized app development, offering a comprehensive range of services tailored to meet the diverse needs of modern businesses. Whether you're looking to streamline your business processes, optimize your IT infrastructure, or enhance customer engagement, our team of 
                                experienced professionals has the expertise and resources to help you achieve your goals.</p>
                        </div>
                        </div> 
                    {/* <div className="hero-service-about-body">
                        <p>Our team is a collective force of top talents, pros, experts, and visionaries from diverse fields. With a passion for excellence, our professionals bring a wealth of experience and knowledge to every project. At Slack, we are committed to delivering nothing short of excellence. From concept to implementation, we maintain the highest standards of quality and craftsmanship, leaving no room for compromise.</p>
                        <ul>
                            <li>
                                <i className="las la-check"></i> Managed Services and Products
                            </li>
                            <li>
                                <i className="las la-check"></i> Flexibility and Adaptability
                            </li>
                            <li>
                                <i className="las la-check"></i> Competitive Advantage
                            </li>
                        </ul>
                    </div>  */}
                </div>
            </div>
        </section> 


        
        {/* <section className="service4-area service-area">
            <div className="custom-container">
                <div className="service-section-header section-header d-flex align-items-end justify-content-between">
                    <div className="left">
                        <h5 className="section-subtitle">WHAT WE’RE OFFERING</h5>
                        <h1 className="section-title">Dealing in all professional <br/>IT services.</h1>
                    </div>
                    <p>One fundamental aspect of IT services is infrastructure management. This involves the design,
                        implementation, and maintenance of the hardware, software, networks, and servers.</p>
                </div>

                <div className="services-list d-flex">
                    <div className="service-card simple-shadow pop-in">
                        <img src="./assets/imgs/service-icon-1.svg" alt="Service Icon" className="service-icon" />
                        <h3><a href="">Development</a></h3>
                        <p>Our development is pixel perfect in all ways.</p>
                    </div>
                    <div className="service-card simple-shadow pop-in">
                        <img src="./assets/imgs/service-icon-2.svg" alt="Service Icon" className="service-icon" />
                        <h3><a href="">Woo Commerce</a></h3>
                        <p>We have a best team for your shopping websites.</p>
                    </div>
                    <div className="service-card simple-shadow pop-in">
                        <img src="./assets/imgs/service-icon-3.svg" alt="Service Icon" className="service-icon" />
                        <h3><a href="">CRM Solutions</a></h3>
                        <p>We enhance customer experiences for success.</p>
                    </div>
                    <div className="service-card simple-shadow pop-in">
                        <img src="./assets/imgs/service-icon-4.svg" alt="Service Icon" className="service-icon" />
                        <h3><a href="">Web Design</a></h3>
                        <p>We create vibrant, intuitive and minimalist web</p>
                    </div>
                    <div className="service-card simple-shadow pop-in">
                        <span className="service-badge">Free!</span>
                        <img src="./assets/imgs/service-icon-5.svg" alt="Service Icon" className="service-icon" />
                        <h3><a href="">IT Support</a></h3>
                        <p>We offers expert assistance for your IT issues.</p>
                    </div>
                </div>
            </div>
        </section> */}


        
         {/* <section className="our-team-2-area">
            <div className="custom-container">
                 <div className="section-header our-team-2-header d-flex align-items-center justify-content-between w-full">
                    <img className="animation-slide-right bg-shape" src="./assets/imgs/bg-shape-10.svg" alt="Shape" />
                    <div className="left">
                        <h5 className="section-subtitle">OUR TEAM</h5>
                        <h1 className="section-title">Our professional experts</h1>
                        <p>Our team is a collective force of top talents, experts, and <br/>
                            visionaries from diverse fields.</p>
                    </div>

                    <a href="contact.html" className="theme-btn">Book an appointment with our expert now</a>
                </div>  
              ?
              </div>
        </section>  */}


        
        <section className="about-service3-area ">
            <div className="custom-container">
                <div className="custom-row align-items-center">
                    <div className="img-box">
                        <img src ="./assets/imgs/about.jpg" alt="About" />
                    </div>

                    <div className="content-box">
                        <h1>OUR VALUES</h1>
                <p>We uphold the highest standards of integrity, honesty, and ethics in all our
                     interactions and dealings. Innovation: We foster a culture of innovation, 
                     creativity, and continuous improvement to drive excellence and stay ahead of the curve. 
                     Customer Focus: We are dedicated to understanding our clients' needs and delivering value-driven solutions that exceed their expectations. Collaboration: We believe in the power of collaboration and teamwork, both internally and with our clients, to achieve shared goals and success. Excellence: We are committed to delivering excellence in everything we do, from project delivery to customer service,
                     to ensure the highest levels of quality and satisfaction.</p>
                       
                    </div>
                </div>
            </div>
        </section>


        
        {/* <section className="cta-area">
            <div className="custom-container">
                <div className="cta-body text-center">
                    <img src="./assets/imgs/bg-shape-11.svg" alt="Shape" className="animation-slide-left bg-shape"/>
                    <div className="our-expert-team-box">
                        <div className="our-expert-team-box-inner d-flex align-items-center">
                            <div className="imgs d-flex align-items-center">
                                <img src="./assets/imgs/small-img-4.png" alt="team" />
                                <img src="./assets/imgs/small-img-3.png" alt="team" />
                                <img src="./assets/imgs/small-img-2.png" alt="team" />
                                <img src="./assets/imgs/small-img-1.png" alt="team" />
                            </div>
                        </div>
                    </div>
                    <h2>Need any further assitance?</h2>
                    <p>Feel free to reach out for any inquiries or assistance.</p>
                    <a href="contact.html" className="theme-btn">Book an appointment now</a>

                </div>
            </div>
        </section> */}

    </div>
 </Layout>
  )
}
