import React from 'react'
import Layout from '../Layout';

export default function Supermarkets() {
  return (
    <Layout>
    <div className='content1'>Supermarkets</div>
    </Layout>
  )
}
