import React, { useEffect } from 'react';
import Layout from './Layout'
import './carrers.css'
import {Link}from "react-router-dom"

export default function Carrers() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return (
  <Layout>
    <div className='content1'>
    <section class="hero-service-wrap hero-section-wrap hero-career-wrap">
            <div class="hero-section-content-wrap">
                <img src="./assets/imgs/bg-shape-1.svg" alt="Shape" class="animation-slide-left bg-shape" />
                <div class="custom-container">
                    <div class="hero-portfolio-body">
                        <div class="hero-section-content text-center">
                            <h5 class="section-subtitle">Career</h5>
                            <h1 class="section-title fade-in">You have great opportunities</h1>
                            <p>If you are a talented and ambitious individual looking to make a mark in your career,
                                we invite you to explore our career opportunities.</p>
                               <Link to="/contact" class="theme-btn">Book an appointment</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="company-service-area career-service-area">
            <div class="custom-container">
                <div class="hero-service-about">
                    <img src="./assets/imgs/hero-career-about.jpg" alt="Service About"/>
                    <div class="hero-service-about-body">
                        <p>Our team is a collective force of top talents, pros, experts, and visionaries from diverse fields. With a passion for excellence, our professionals bring a wealth of experience and knowledge to every project. At Slack, we are committed to delivering nothing short of excellence. From concept to implementation, we maintain the highest standards of quality and craftsmanship, leaving no room for compromise.</p>
                        <ul>
                            <li>
                                <i class="las la-check"></i> Managed Services and Products
                            </li>
                            <li>
                                <i class="las la-check"></i> Flexibility and Adaptability
                            </li>
                            <li>
                                <i class="las la-check"></i> Competitive Advantage
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


      
       
        
        <section class="about-service3-area">
            <div class="custom-container">
                <div class="custom-row align-items-center">
                    <div class="img-box">
                        <img src="./assets/imgs/about-service-3.png" alt="About" />
                    </div>

                    <div class="content-box">
                        <h1>Why our services are 
                            better than others?</h1>
                        <p>At Lakshya Software Technologies, we believe our careers are uniquely positioned to stand out in the competitive tech landscape due to our distinct approach and values. Here are several key differentiators that make careers 
                            at Lakshya not only rewarding but also transformative for our team members:</p>
                            <p>Our culture emphasizes innovation and creativity. We encourage our employees to think outside the box and take ownership of their projects. This empowerment fosters a dynamic environment where new ideas are welcomed,
                                 leading to groundbreaking solutions that set us apart in the industry.</p>
                        <ul>
                            <li>
                                <i class="las la-check"></i> PPD Development
                            </li>
                            <li>
                                <i class="las la-check"></i> Quick Response
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        
      
        
        <section class="contact-area">
            <div class="custom-container">
                <div class="custom-row">
                    <div class="contact-form-wrap">
                        <div class="contact-form-body">
                            <h5 class="section-subtitle">Contact</h5>
                            <h1 class="section-title">Lets get in touch</h1>
                            <p>You can reach us anytime via <a href="mailto: contact@lakshya-soft.com"> contact@lakshya-soft.com</a></p>
                            <form action="assets/mail/contact.php" method="POST" class="contact-form">
                                <div class="input-row">
                                    <div class="input-group">
                                        <label for="first_name">First Name</label>
                                        <input id="first_name" type="text" name="first_name" placeholder="First Name" />
                                    </div>
                                    <div class="input-group">
                                        <label for="last_name">Last Name</label>
                                        <input id="last_name" type="text" name="last_name" placeholder="Last Name" />
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="input-group">
                                        <label for="email">Email</label>
                                        <input type="email" id="email" name="email" placeholder="Your Email" />
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="input-group phone-number">
                                        <select class="number-prefix">
                                            <option value="uk">IND</option>
                                        </select>
                                        <label for="phone_number">Phone Number</label>
                                        <input id="phone_number" type="text" name="phone_number" placeholder="Your Number" />
                                    </div>
                                    <div class="input-group">
                                        <label for="country">Country</label>
                                        <input type="text" id="homeland" name="country" placeholder="Your Country" />
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="input-group">
                                        <label for="message">Message</label>
                                        <textarea name="message" id="message" placeholder="Leave us a message...."></textarea>
                                    </div>
                                </div>
                                <div class="input-row">
                                    <div class="input-group input-checkbox">
                                        <input type="checkbox" id="privacy_policy_accept"/>
                                        <label for="privacy_policy_accept">You agree to our <a href="#">terms and conditions.</a></label>
                                    </div>
                                </div>

                                <div class="input-row">
                                    <div class="input-group">
                                        <button id="submit" class="theme-btn">Get Started</button>
                                    </div>
                                </div>
                              
                                <div class="input-row">
                                    <div class="input-group alert-notification">
                                        <div id="alert-message" class="alert-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="contact-experience">
                        <ul>
                            <li>
                                <h1>9+ <span>Years</span></h1>
                                <p>Field Experience </p>
                            </li>
                            <li>
                                <h1>350+ <span>Projects</span></h1>
                                <p>Done Around World </p>
                            </li>
                            <li>
                                <h1>99%</h1>
                                <p>Client Satisfaction</p>
                            </li>
                            <li>
                                <h1>2015 <span>Year</span></h1>
                                <p>Established On</p>
                            </li>
                            <li>
                                <h1>2 <span>Mins</span></h1>
                                <p>Response Time</p>
                            </li>
                        </ul>
                    </div>

                    <div class="contact-infos">
                        <div class="contact-infos-inner">
                            <div class="contact-info">
                                <img src="./assets/imgs/support-icon.svg" alt="Support" />
                                <h3>Contact Info</h3>
                                <p>
                                8143233244 <br/>
                                contact@lakshya-soft.com
                                </p>
                            </div>
                            <div class="contact-office-info contact-info">
                                <img src="./assets/imgs/map-icon.svg" alt="Map" />
                                <h3>Visit our office</h3>
                                <p>Lakshya Software Technologies Private Limited, Dno 1-89/A/7, Plot No 7, 3rd Floor, Trendz Axis, Hyderabad, Madhapur, Serilingampally, Rangareddy,  Telangana, 500081.</p>
                            </div>

                            <ul class="contact-social-links">
                                <li>
                                    <a href="#">
                                        <i class="iconoir-facebook"></i> facebook
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="iconoir-twitter"></i> Twitter
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="iconoir-instagram"></i> Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="iconoir-linkedin"></i> linkedin
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
   </Layout>
  )
}
