import React,{useEffect} from 'react'

import Layout from '../Layout';
import './sa.css'


export default function Sa() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return(
  <Layout>
   <div className='content1'>

<section class="hero-service-wrap hero-section-wrap hero-overview-wrap">
    <div class="hero-section-content-wrap">
        <div class="custom-container">
            <div class="hero-portfolio-body">
                <div class="hero-section-content text-center">
                    <h5 class="section-subtitle">System Assurance</h5>
                    <h1 class="section-title fade-in">Your Partner in Comprehensive System Assurance Services</h1>
                    <p>Lakshya Software Technologies has carved a niche in system assurance consultancy, particularly 
                        for complex infrastructure projects such as railways. Our services are designed to address the unique challenges of large-scale
                        projects by prioritizing safety, quality, and operational efficiency.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature3-area feature3-style-2">
    
    <div class="custom-container">
        <div class="feature3-body">
            <div class="section-header">
                <h5 class="section-subtitle">core services</h5>
                <h1 class="section-title">Design Engineering Services
                    </h1>
            </div>

            <div class="feature3-lists">
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Feasibility Studies & Conceptual Design</h3>
                    <p>Independent oversight to verify the design and construction meet project specifications
                         and quality standards.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Detail Design Consultancy</h3>
                    <p>Expert safety audits and assessments to certify systems meet all safety standards and regulations.</p>
                </div>
              
               
           
             
              
             
               
            </div>
            
        </div>
    </div>
</section>



{/* <section class="new-release-hero-img-area">
    <div class="custom-container">
        <div class="new-release-img-box">
            <img src="./assets/imgs/service-details-1.jpg" alt="Service Details"/>
        </div>
    </div>
</section> */}



{/* <section class="about-service7-area">
    <div class="custom-container">
        <div class="about-service7-body d-flex align-items-center">
            <div class="about-service7-content">
                <div class="section-header">
                    <h5 class="section-subtitle">SOFTWARE DEVELOPMENT</h5>
                    <h1 class="section-title">Engine of progress</h1>
                    <p>Development as stone of innovation.</p>
                </div>
                <p>Software development is the stab process of designing, programming, solids to testing, and maintaining software as the applications and systems. It empowers us to turn ideas</p>
                <p>Software developers, often rest referred to as coders or programmers, are sat the creative minds behind the digital innovations we use daily. They possess a unique blend of</p>
                <p>Developers write lines of code by state using programming languages such as Python, Java, JavaScript, and C++, </p>
            </div>
            <div class="about-service7-img-box d-flex">
                <img src="./assets/imgs/service-details-2.jpg" alt="Service Details"/>
                <img src="./assets/imgs/service-details-3.jpg" alt="Service Details"/>
            </div>
        </div>
    </div>
</section> */}



{/* <section class="about-service3-area about-service3-style-2">
    <img src="./assets/imgs/bg-shape-3.svg" alt="Shape" class="animation-slide-right bg-shape"/>
    <div class="custom-container">
        <div class="custom-row align-items-center">
            <div class="img-box">
                <img src="./assets/imgs/about-service-7.jpg" alt="About" />
            </div>

            <div class="content-box">
                <h5 class="section-subtitle">IMPORTANCE</h5>
                <h1 class="section-title">The Role of Developers</h1>
                <p>Software developers, often referred to as coders or programmers, are the creative minds behind the digital innovations we use daily.</p>
                <ul>
                    <li>
                        <i class="las la-check"></i> PPD Development
                    </li>
                    <li>
                        <i class="las la-check"></i> Easy to Use
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section> */}



<section class="feature3-area feature3-style-2 ">
    
    <div class="custom-container">
        <div class="feature3-body">
            <div class="section-header">
                <h5 class="section-subtitle">core services</h5>
                <h1 class="section-title">System Assurance Consultancy: 
                    </h1>
            </div>

            <div class="feature3-lists">
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Reliability, Availability, Maintainability & Safety (RAMS)</h3>
                    <p>Comprehensive planning and analysis to achieve operational efficiency, cost optimization, and safety assurance. </p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Software Assurance</h3>
                    <p>Validating software systems for functionality, compliance, and reliability
                        in alignment with EN 50128 standards.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Cybersecurity </h3>
                    <p>Ensuring robust system protection against cyber threats with compliance to IEC 62443.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Requirement & Configuration Management (RM/CM)</h3>
                    <p>Rigorous management practices that maintain traceability and consistency across the system
                         lifecycle.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Electromagnetic Compatibility/Interference (EMC/EMI)</h3>
                    <p>Adherence to global standards to prevent electromagnetic interference and ensure seamless system
                         functionality.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Earthing & Bonding (E&B)</h3>
                    <p>Providing advanced solutions to enhance electrical safety and minimize risks in infrastructure.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Human Factor Management/Ergonomics Study (HFM)</h3>
                    <p> Incorporating user-centric designs that enhance interaction, minimize errors, and improve operational safety.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Fire Life Safety (FLS)</h3>
                    <p>Developing and implementing fire risk mitigation strategies that align with regulatory requirements and promote safety.</p>
                </div>
               
            </div>
        </div>
    </div>
</section>
<section class="feature3-area feature3-style-2">
    
    <div class="custom-container">
        <div class="feature3-body">
            <div class="section-header">
                <h5 class="section-subtitle">core services</h5>
                <h1 class="section-title">Specialised Independent Consultancy Services
                    </h1>
            </div>

            <div class="feature3-lists">
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Independent Checking Engineer (ICE)</h3>
                    <p>Independent oversight to verify the design and construction meet project specifications
                         and quality standards.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Independent Safety Assessment (ISA)</h3>
                    <p>Expert safety audits and assessments to certify systems meet all safety standards and regulations.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Independent Verification & Validation (IV&V) </h3>
                    <p>Objective assessment of systems to ensure they meet design specifications and operational
                         requirements.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Independent Validation & Testing Engineer (IV & TE)</h3>
                    <p>Validation and testing of critical systems to ensure reliable and compliant operation.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>3rd Party Verifier (3PV)</h3>
                    <p>Independent evaluation of system components and processes to enhance confidence in project outcomes.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Independent Cybersecurity Assessment</h3>
                    <p>Rigorous evaluation of cybersecurity measures to identify vulnerabilities and
                         ensure robust protection.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Technical Audits</h3>
                    <p>Comprehensive audits to assess technical compliance, performance, and quality across project phases.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Accident Investigation</h3>
                    <p>Expert analysis to identify root causes and provide actionable recommendations for 
                        preventing future incidents.</p>
                </div>
               
            </div>
            
        </div>
    </div>
</section>
<section class="feature3-area feature3-style-2">
    
    <div class="custom-container">
        <div class="feature3-body">
            <div class="section-header">
                <h5 class="section-subtitle">core services</h5>
                <h1 class="section-title">Technical Management and other specialised Consultancy services: 
                    </h1>
            </div>

            <div class="feature3-lists">
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Project Management Consultancy (PMC)</h3>
                    <p>•	End-to-end project management services, ensuring timely delivery,
                         budget control, and alignment with project goals. </p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Interface Management Consultancy (IM)</h3>
                    <p>•	Managing interactions between various subsystems to ensure seamless 
                        integration and operational efficiency.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>System Integration Consultancy (SI)</h3>
                    <p>Coordination and oversight of system integration to achieve functional and operational harmony.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Construction Management Consultancy (CM)</h3>
                    <p>•	Professional management of construction activities to ensure project 
                        timelines, quality, and budget adherence.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Operations & Maintenance Consultancy (O&M)</h3>
                    <p>Optimizing operational efficiency and maintenance practices for sustainable 
                        and cost-effective system performance.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Earthing & Bonding (E&B)</h3>
                    <p>Advanced solutions for earthing and bonding to enhance electrical safety and minimize risks.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Technical Management & Other Consultancy</h3>
                    <p>Providing tailored technical solutions and expert guidance for unique project needs.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>General Consultancy (GC)</h3>
                    <p>Holistic support across project phases, offering insights and 
                        expertise for successful project execution.</p>
                </div>
               
            </div>
        </div>
    </div>
</section>




{/* <section class="testimonial-area">
    <div class="custom-container">
        
        <div class="testimonial-slider-wrap">
            <div class="testimonial-slider swiper">
                
                <div class="swiper-wrapper">
                    <div class="swiper-slide testimonial-item">
                        <div class="testimonial-item-body">
                            <img class="animation-slide-right bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                            <span class="platform-name">
                                <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                            </span>
                            <h1>Platform helped me to increase my sales and revenue
                                generated by Lakshya Software Technologies</h1>
                            <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                            <div class="author-box d-flex align-items-center">
                                <img src="./assets/imgs/testimonial-1.jpg" alt="Testimonial"/>
                                <div class="author-box-content">
                                    <h4>Serana Belluci</h4>
                                    <p>Product Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide testimonial-item">
                        <div class="testimonial-item-body">
                            <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                            <span class="platform-name">
                                <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                            </span>
                            <h1>Lakshya give the best service and top customer
                                support. Really like to have more projects with you</h1>
                            <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                            <div class="author-box d-flex align-items-center">
                                <img src="./assets/imgs/testimonial-2.jpeg" alt="Testimonial"/>
                                <div class="author-box-content">
                                    <h4>Megan Clarke</h4>
                                    <p>CEO, Sanlab</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide testimonial-item">
                        <div class="testimonial-item-body">
                            <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                            <span class="platform-name">
                                <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                            </span>
                            <h1>We’ve been using Lakshya for a few years, It is very 
                                simple and easy to approach team and management</h1>
                            <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                            <div class="author-box d-flex align-items-center">
                                <img src="./assets/imgs/testimonial-3.jpeg" alt="Testimonial"/>
                                <div class="author-box-content">
                                    <h4>Rachel Olsem</h4>
                                    <p>Full Stack Developer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
            
            
            <div class="swiper-button-prev"><i class="las la-arrow-left"></i></div>
            <div class="swiper-button-next"><i class="las la-arrow-right"></i></div>
        </div>

    </div>
</section> */}

</div>
    
 
 </Layout>
  )
}

