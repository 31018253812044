import React,{useEffect} from 'react'

import Layout from '../Layout';

export default function Product() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return(
  <Layout>
    <div className='content1'>
    <section class="hero-service-wrap hero-section-wrap hero-overview-wrap">
            <div class="hero-section-content-wrap">
                <div class="custom-container">
                    <div class="hero-portfolio-body">
                        <div class="hero-section-content text-center">
                            <h5 class="section-subtitle">Development</h5>
                            <h1 class="section-title fade-in">Development is the pillar of
                                our company</h1>
                            <p>
                            Software development is the process of designing, programming, testing, and maintaining software applications and systems.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="new-release-hero-img-area">
            <div class="custom-container">
                <div class="new-release-img-box">
                    <img src="./assets/imgs/service-details-1.jpg" alt="Service Details"/>
                </div>
            </div>
        </section>


        
        <section class="about-service7-area">
            <div class="custom-container">
                <div class="about-service7-body d-flex align-items-center">
                    <div class="about-service7-content">
                        <div class="section-header">
                            <h5 class="section-subtitle">SOFTWARE DEVELOPMENT</h5>
                            <h1 class="section-title">Engine of progress</h1>
                            <p>Development as stone of innovation.</p>
                        </div>
                        <p>Software development is the stab process of designing, programming, solids to testing, and maintaining software as the applications and systems. It empowers us to turn ideas</p>
                        <p>Software developers, often rest referred to as coders or programmers, are sat the creative minds behind the digital innovations we use daily. They possess a unique blend of</p>
                        <p>Developers write lines of code by state using programming languages such as Python, Java, JavaScript, and C++, </p>
                    </div>
                    <div class="about-service7-img-box d-flex">
                        <img src="./assets/imgs/service-details-2.jpg" alt="Service Details"/>
                        <img src="./assets/imgs/service-details-3.jpg" alt="Service Details"/>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="about-service3-area about-service3-style-2">
            <img src="./assets/imgs/bg-shape-3.svg" alt="Shape" class="animation-slide-right bg-shape"/>
            <div class="custom-container">
                <div class="custom-row align-items-center">
                    <div class="img-box">
                        <img src="./assets/imgs/about-service-7.jpg" alt="About" />
                    </div>

                    <div class="content-box">
                        <h5 class="section-subtitle">IMPORTANCE</h5>
                        <h1 class="section-title">The Role of Developers</h1>
                        <p>Software developers, often referred to as coders or programmers, are the creative minds behind the digital innovations we use daily.</p>
                        <ul>
                            <li>
                                <i class="las la-check"></i> PPD Development
                            </li>
                            <li>
                                <i class="las la-check"></i> Easy to Use
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        
        {/* <section class="feature3-area feature3-style-2">
            
            <div class="custom-container">
                <div class="feature3-body">
                    <div class="section-header">
                        <h5 class="section-subtitle">FEATURES</h5>
                        <h1 class="section-title">Key aspects of our BPM services with a focus on SAP include:
                            </h1>
                    </div>

                    <div class="feature3-lists">
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Process Design and Modeling</h3>
                            <p>Our team of experienced BPM consultants works closely with your organization to understand your business processes, identify areas for improvement,
                                and design optimized process models using SAP tools and methodologies. </p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Implementation and Integration</h3>
                            <p>We assist in the seamless implementation and integration of SAP BPM solutions into your existing IT infrastructure, ensuring compatibility, 
                                scalability, and minimal disruption to your operations.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Automation and Orchestration</h3>
                            <p>Utilizing SAP's advanced automation capabilities, we automate repetitive tasks, streamline workflows, and orchestrate complex processes to improve efficiency,
                                 reduce manual intervention, and accelerate time-to-market.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Monitoring and Optimization</h3>
                            <p>We provide ongoing monitoring and optimization services to continuously fine-tune your BPM solutions, identify bottlenecks, and implement enhancements
                                 to ensure optimal performance and alignment with your evolving business needs.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Training and Support: </h3>
                            <p>Our comprehensive training and support programs empower your team to effectively leverage SAP BPM solutions, maximize productivity, 
                                and drive user adoption across your organization.</p>
                        </div>
                     
                    </div>
                </div>
            </div>
        </section> */}
        <section class="feature3-area feature3-style-2">
<div class="custom-container">
<div class="feature3-body">
    <div class="section-header">
        <h6 class="section-subtitle">FEATURES</h6>
<h2 class="section-title">Boosting your
business using
our team</h2>    </div>

    <div class="feature3-lists">

                    <div class="feature-box">
                <i class="icon-box las la-check"></i>
                <h4>Requirements Gathering</h4>
                <p>The development process starts with gathering and analyzing the requirements from stakeholders, including clients, end-users, and business analysts 
requirements from stakeholders, </p>
            </div>
                    <div class="feature-box">
                <i class="icon-box las la-check"></i>
                <h4>Devops Interoperability</h4>
                <p>In today's interconnected world, software often needs to integrate with other systems and platforms. Developing software with interoperability in mind ensures.</p>
            </div>
                    <div class="feature-box">
                <i class="icon-box las la-check"></i>
                <h4>Data Integration</h4>
                <p>Software applications should be designed and developed with scalability in mind. As user bases grow and data increases, the software should be able to handle.</p>
            </div>
                    <div class="feature-box">
                <i class="icon-box las la-check"></i>
                <h4>Analytics Performance</h4>
                <p>The development process starts with gathering and analyzing the requirements from stakeholders, including clients, end and business analysts. As user bases grow and data increases</p>
            </div>
                    <div class="feature-box">
                <i class="icon-box las la-check"></i>
                <h4>Data Integration</h4>
                <p>Software applications should be designed and developed with scalability in mind. As user bases grow and data increases, the software should be able to handle.</p>
            </div>
                    <div class="feature-box">
                <i class="icon-box las la-check"></i>
                <h4>Analytics Performance</h4>
                <p>The development process starts with gathering and analyzing the requirements from stakeholders, including clients, end and business analysts. As user bases grow and data increases</p>
            </div>
               
    </div>
</div>
</div>
</section>






        <section class="testimonial-area">
            <div class="custom-container">
                
                <div class="testimonial-slider-wrap">
                    <div class="testimonial-slider swiper">
                        
                        <div class="swiper-wrapper">
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="animation-slide-right bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Platform helped me to increase my sales and revenue
                                        generated by Lakshya Software Technologies</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-1.jpg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Serana Belluci</h4>
                                            <p>Product Designer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Lakshya give the best service and top customer
                                        support. Really like to have more projects with you</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-2.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Megan Clarke</h4>
                                            <p>CEO, Sanlab</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>We’ve been using Lakshya for a few years, It is very 
                                        simple and easy to approach team and management</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-3.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Rachel Olsem</h4>
                                            <p>Full Stack Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    
                    
                    <div class="swiper-button-prev"><i class="las la-arrow-left"></i></div>
                    <div class="swiper-button-next"><i class="las la-arrow-right"></i></div>
                </div>

            </div>
        </section>
    </div>
    </Layout>
  )
}
