import React, { useEffect } from 'react';

import Layout from '../Layout';


export default function Systemdesign() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return(
  <Layout>
   <div className='content1'>

   <section class="hero-service-wrap hero-section-wrap hero-overview-wrap">
    <div class="hero-section-content-wrap">
        <div class="custom-container">
            <div class="hero-portfolio-body">
                <div class="hero-section-content text-center">
                    <h5 class="section-subtitle">System Design Engineering:</h5>
                    <h1 class="section-title fade-in">System Design Engineering</h1>
                    <p>At Lakshya Software Technologies, we specialize in delivering complete system designs, guiding you through every phase—from initial architectural planning to final certification. Our comprehensive services ensure that your electronic systems not only meet
                         industry standards but are also optimized for performance, compliance, and reliability.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature3-area feature3-style-2">
    
    <div class="custom-container">
        <div class="feature3-body">
            <div class="section-header">
                <h5 class="section-subtitle">core solutions</h5>
                <h1 class="section-title">System Design Engineering
                    </h1>
            </div>

            <div class="feature3-lists">
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>
                    End-to-End System Design Solutions</h3>
                    <p>At Lakshya Software Technologies, we provide end-to-end system design solutions that encompass every stage of development—from initial concept and architecture to final implementation and deployment. Our holistic approach ensures that all system components are integrated seamlessly, 
                        optimized for performance, and meet all required specifications.</p>
                </div>
                <div class="feature-box">
                    <i class="icon-box las la-check"></i>
                    <h3>Custom System Design for Specific Industries</h3>
                    <p>At Lakshya Software Technologies, we offer custom system design solutions tailored to the unique needs of various industries, including aerospace, healthcare, automotive, telecommunications, and more. We understand that each industry has distinct challenges, and we are committed to providing solutions that address specific regulatory requirements, performance standards, and operational demands.</p>
                </div>
              
               
           
             
              
             
               
            </div>
            
        </div>
    </div>
</section>



{/* <section class="new-release-hero-img-area">
    <div class="custom-container">
        <div class="new-release-img-box">
            <img src="./assets/imgs/service-details-1.jpg" alt="Service Details"/>
        </div>
    </div>
</section> */}



{/* <section class="about-service7-area">
    <div class="custom-container">
        <div class="about-service7-body d-flex align-items-center">
            <div class="about-service7-content">
                <div class="section-header">
                    <h5 class="section-subtitle">SOFTWARE DEVELOPMENT</h5>
                    <h1 class="section-title">Engine of progress</h1>
                    <p>Development as stone of innovation.</p>
                </div>
                <p>Software development is the stab process of designing, programming, solids to testing, and maintaining software as the applications and systems. It empowers us to turn ideas</p>
                <p>Software developers, often rest referred to as coders or programmers, are sat the creative minds behind the digital innovations we use daily. They possess a unique blend of</p>
                <p>Developers write lines of code by state using programming languages such as Python, Java, JavaScript, and C++, </p>
            </div>
            <div class="about-service7-img-box d-flex">
                <img src="./assets/imgs/service-details-2.jpg" alt="Service Details"/>
                <img src="./assets/imgs/service-details-3.jpg" alt="Service Details"/>
            </div>
        </div>
    </div>
</section> */}



{/* <section class="about-service3-area about-service3-style-2">
    <img src="./assets/imgs/bg-shape-3.svg" alt="Shape" class="animation-slide-right bg-shape"/>
    <div class="custom-container">
        <div class="custom-row align-items-center">
            <div class="img-box">
                <img src="./assets/imgs/about-service-7.jpg" alt="About" />
            </div>

            <div class="content-box">
                <h5 class="section-subtitle">IMPORTANCE</h5>
                <h1 class="section-title">The Role of Developers</h1>
                <p>Software developers, often referred to as coders or programmers, are the creative minds behind the digital innovations we use daily.</p>
                <ul>
                    <li>
                        <i class="las la-check"></i> PPD Development
                    </li>
                    <li>
                        <i class="las la-check"></i> Easy to Use
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section> */}










{/* <section class="testimonial-area">
    <div class="custom-container">
        
        <div class="testimonial-slider-wrap">
            <div class="testimonial-slider swiper">
                
                <div class="swiper-wrapper">
                    <div class="swiper-slide testimonial-item">
                        <div class="testimonial-item-body">
                            <img class="animation-slide-right bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                            <span class="platform-name">
                                <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                            </span>
                            <h1>Platform helped me to increase my sales and revenue
                                generated by Lakshya Software Technologies</h1>
                            <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                            <div class="author-box d-flex align-items-center">
                                <img src="./assets/imgs/testimonial-1.jpg" alt="Testimonial"/>
                                <div class="author-box-content">
                                    <h4>Serana Belluci</h4>
                                    <p>Product Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide testimonial-item">
                        <div class="testimonial-item-body">
                            <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                            <span class="platform-name">
                                <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                            </span>
                            <h1>Lakshya give the best service and top customer
                                support. Really like to have more projects with you</h1>
                            <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                            <div class="author-box d-flex align-items-center">
                                <img src="./assets/imgs/testimonial-2.jpeg" alt="Testimonial"/>
                                <div class="author-box-content">
                                    <h4>Megan Clarke</h4>
                                    <p>CEO, Sanlab</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide testimonial-item">
                        <div class="testimonial-item-body">
                            <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                            <span class="platform-name">
                                <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                            </span>
                            <h1>We’ve been using Lakshya for a few years, It is very 
                                simple and easy to approach team and management</h1>
                            <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                            <div class="author-box d-flex align-items-center">
                                <img src="./assets/imgs/testimonial-3.jpeg" alt="Testimonial"/>
                                <div class="author-box-content">
                                    <h4>Rachel Olsem</h4>
                                    <p>Full Stack Developer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
            
            
            <div class="swiper-button-prev"><i class="las la-arrow-left"></i></div>
            <div class="swiper-button-next"><i class="las la-arrow-right"></i></div>
        </div>

    </div>
</section> */}

</div>
    
 
 </Layout>
  )
}

