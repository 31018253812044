import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div>
      <main class="main-page homepage header">
        <div class="header-bar">
          <div class="custom-container">
            <div class="header-bar-body d-flex align-items-center justify-content-between">
              <div class="left"></div>
              <div class="right">
                <p>
                  Level up your business with <nbsp></nbsp>
                  <a href="about.html" data-word="SOFT" id="dataWord">
                    LAKSHYA.<nbsp></nbsp>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <header class="header-area">
          <div class="custom-container">
            <div class="custom-row align-items-center justify-content-between">
              <div class="header-left d-flex align-items-center">
                <a href="./index.html" class="logo">
                  <img src="./assets/imgs/lst.png" alt="Logo" />
                </a>
                <div class="header-left-right">
                <Link to="/contact" class="theme-btn">

                  <a href="" class="theme-btn">
                    Contact Us
                  </a></Link>
                  <span class="menu-bar">
                    <i class="las la-bars"></i>
                  </span>
                </div>
                <nav class="navbar-wrapper">
                  <span class="close-menu-bar">
                    <i class="las la-times"></i>
                  </span>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">About us</Link>
                    </li>
                    <li class="mega-menu-item">
                      <Link to="#" onClick={(e) => e.preventDefault()}>
                        Solutions
                      </Link>
                      <span class="dropdown-menu-item-icon">
                        <i class="las la-angle-down"></i>
                      </span>
                      <div class="mega-menu">
                        <div class="mega-menu-inner">
                          <div class="custom-container d-flex">
                            <div class="left">
                              <div class="mega-menu-link-wrap d-flex align-items-start justify-content-between">
                                <div class="mega-menu-service-cards align-items-start">
                                  <div class="mega-menu-service-card">
                                    <span class="icon">
                                      <img
                                        src="./assets/imgs/hwd-icon-1.svg"
                                        alt="Service"
                                      />
                                    </span>
                                    <div class="content">
                                      <h2>
                                        <Link to="/brainstorming">Idea</Link>
                                      </h2>
                                      <p>Exploration</p>
                                    </div>
                                  </div>
                                  <div class="mega-menu-service-card">
                                    <span class="icon">
                                      <img
                                        src="./assets/imgs/hwd-icon-4.svg"
                                        alt="Service"
                                      />
                                    </span>
                                    <div class="content">
                                      <h2>
                                        <Link to="/product">Frontend</Link>
                                      </h2>
                                      <p>Styling</p>
                                    </div>
                                  </div>
                                  <div class="mega-menu-service-card">
                                    <span class="icon">
                                      <img
                                        src="./assets/imgs/hwd-icon-2.svg"
                                        alt="Service"
                                      />
                                    </span>
                                    <div class="content">
                                      <h2>
                                        <Link to="/seo">SEO</Link>
                                      </h2>
                                      <p>Performance</p>
                                    </div>
                                  </div>
                                  <div class="mega-menu-service-card">
                                    <span class="icon">
                                      <img
                                        src="./assets/imgs/hwd-icon-3.svg"
                                        alt="Service"
                                      />
                                    </span>
                                    <div class="content">
                                      <h2>
                                        <Link to="/frontend">Frontend</Link>
                                      </h2>
                                      <p>Development</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="mega-menu-links d-flex">
                                  <div class="mega-menu-link">
                                    <h3 className="solutionsheading">
                                      Solutions
                                    </h3>
                                    <ul>
                                    <li>
                                        <Link to="/vlsi">VLSI Engineering & Silicon Design</Link>
                                      </li>
                                      <li>
                                        <Link to="/es">Embedded Systems</Link>
                                      </li>
                                      <li>
                                        <Link to="/iot">Internet of Things</Link>
                                      </li>
                                      <li>
                                        <Link to="/systemdesign">System Design Engineering </Link>
                                      </li>
                                      <li>
                                        <Link to="/as">
                                          Automotive & Smart Solutions
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/artificial">
                                          Artificial Intelligence & Advanced
                                          Analytics
                                        </Link>
                                      </li>
                                      <li>
                                        
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <li class="mega-menu-item">
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Services
                                </Link>

                                <span class="dropdown-menu-item-icon">
                                  <i class="las la-angle-down"></i>
                                </span>
                              </li>

                              <div class="mega-meu-footer d-flex align-items-center justify-content-between w-full">
                                <ul class="mega-menu-social d-flex align-items-center">
                                  <li>
                                    <a href="https://www.facebook.com/LakshyaSoftware">
                                      <i class="iconoir-facebook"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://x.com/LakshyaSoftware?t=U_omGbdaa-M6XiYxEfV24Q&s=09">
                                      <i class="iconoir-twitter"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.instagram.com/lakshyasoftware?igsh=b3B6ZTB2b2k3cHU2">
                                      <i class="iconoir-instagram"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.linkedin.com/company/lakshya-software-technologies/">
                                      <i class="iconoir-linkedin"></i>
                                    </a>
                                  </li>
                                </ul>

                                <p>
                                  Looking for new career?{" "}
                                  <a href="career.html">We're hiring</a>
                                </p>
                              </div>
                            </div>
                            <div class="right">
                              <div class="mega-menu-ads">
                                <img src="./assets/imgs/ipad.jpg" alt="iPad" />
                                <h2>Our product hits</h2>
                                <p>
                                  Our new best IT product of the <br></br>year
                                  2023.
                                </p>
                                <a href="case-studie-single.html">View more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="mega-menu-item">
                      <Link to="#" onClick={(e) => e.preventDefault()}>
                        Services
                      </Link>

                      <span class="dropdown-menu-item-icon">
                        <i class="las la-angle-down"></i>
                      </span>
                      <div class="mega-menu">
                        <div class="mega-menu-inner">
                          <div class="custom-container d-flex">
                            <div class="left">
                              <div class="mega-menu-link-wrap d-flex align-items-start justify-content-between">
                                <div class="mega-menu-service-cards align-items-start">
                                  <div class="mega-menu-service-card ">
                                    <span class="icon">
                                      <img
                                        src="./assets/imgs/hwd-icon-1.svg"
                                        alt="Service"
                                      />
                                    </span>
                                    <div class="content">
                                      <h2>
                                        <Link to="/brainstorming">
                                          Brainstorming
                                        </Link>
                                      </h2>
                                      <p>Ideas</p>
                                    </div>
                                  </div>
                                  <div class="mega-menu-service-card">
                                    <span class="icon">
                                      <img
                                        src="./assets/imgs/hwd-icon-4.svg"
                                        alt="Service"
                                      />
                                    </span>
                                    <div class="content">
                                      <h2>
                                        <Link to="/product">Product</Link>
                                      </h2>
                                      <p>Design</p>
                                    </div>
                                  </div>
                                  <div class="mega-menu-service-card">
                                    <span class="icon">
                                      <img
                                        src="./assets/imgs/hwd-icon-2.svg"
                                        alt="Service"
                                      />
                                    </span>
                                    <div class="content">
                                      <h2>
                                        <Link to="/seo">SEO</Link>
                                      </h2>
                                      <p>Optimization</p>
                                    </div>
                                  </div>
                                  <div class="mega-menu-service-card">
                                    <span class="icon">
                                      <img
                                        src="./assets/imgs/hwd-icon-3.svg"
                                        alt="Service"
                                      />
                                    </span>
                                    <div class="content">
                                      <h2>
                                        <Link to="/frontend">Frontend</Link>
                                      </h2>
                                      <p>Development</p>
                                    </div>
                                  </div>
                                </div>

                                <div class="mega-menu-links ">
                                  <div class="mega-menu-link">
                                    <h3 className="servicesheading">
                                      Services
                                    </h3>
                                    <ul className="list-container">
                                      <li>
                                        <Link to="/bpm">
                                          Business Process Management
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/sa">System Assurance</Link>
                                      </li>
                                      <li>
                                        <Link to="/crm">
                                          CRM implementation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/csd">
                                          Customized software Development
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/erp">
                                          ERP implementation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/psm">
                                          Procurement Supply Management{" "}
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/epm">
                                          Enterprise Process Management
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/hcm">
                                          Human capital Management
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/cs">Cloud Services</Link>
                                      </li>
                                    
                                      <li>
                                        <Link to="/ai">
                                          Artificial intelligence & Machine
                                          Learning
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/specialized">
                                        Specialized Technical Consultancy
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/verification">
                                        Verification & Validation
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div class="mega-meu-footer d-flex align-items-center justify-content-between w-full">
                                <ul class="mega-menu-social d-flex align-items-center">
                                  <li>
                                    <a href="https://www.facebook.com/LakshyaSoftware">
                                      <i class="iconoir-facebook"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://x.com/LakshyaSoftware?t=U_omGbdaa-M6XiYxEfV24Q&s=09">
                                      <i class="iconoir-twitter"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.instagram.com/lakshyasoftware?igsh=b3B6ZTB2b2k3cHU2">
                                      <i class="iconoir-instagram"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.linkedin.com/company/lakshya-software-technologies/">
                                      <i class="iconoir-linkedin"></i>
                                    </a>
                                  </li>
                                </ul>

                                <p>
                                  Looking for new career?{" "}
                                  <a href="career.html">We're hiring</a>
                                </p>
                              </div>
                            </div>
                            <div class="right">
                              <div class="mega-menu-ads">
                                <img src="./assets/imgs/ipad.jpg" alt="iPad" />
                                <h2>Our product hits</h2>
                                <p>
                                  Our new best IT product of the <br></br>year
                                  2023.
                                </p>
                                <a href="case-studie-single.html">View more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link to="/carrers">Carrers</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="header-right">
                <div class="header-contact-info d-flex align-items-center">
                  <div class="phone-number">
                    <a href="tel:8143233244">
                      Call Us
                      <i class="iconoir-arrow-up-right"></i>
                    </a>
                    8143233244
                  </div>
                  <Link to="/contact" class="theme-btn">
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      </main>
    </div>
  );
}
