import React,{useEffect} from 'react'
import Layout from '../Layout';

export default function Csd () {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []); // Runs only on component mount
  return(
    <Layout>
 
    <div  className='content1'>
    <section class="hero-service-wrap hero-section-wrap hero-overview-wrap">
            <div class="hero-section-content-wrap">
                <div class="custom-container">
                    <div class="hero-portfolio-body">
                        <div class="hero-section-content text-center">
                            <h5 class="section-subtitle"> Customized Software Development</h5>
                            <h1 class="section-title fade-in">Development is the pillar of
                                our company</h1>
                            <p>In today's rapidly evolving digital landscape, off-the-shelf software solutions may not 
                                always align perfectly with the specific requirements and objectives of your organization. 
                                That's where we come in. At Lakshya, we specialize in designing, developing, and deploying custom software solutions 
                                that are precisely tailored to address your business challenges and drive success.</p><br></br>
                                <p>Our team of experienced software engineers, developers, and designers work closely with you
                                     to understand your business processes, goals, and pain points. Armed with this knowledge, we collaborate with you to conceptualize, design, and develop bespoke software solutions
                                     that are intuitive, scalable, and aligned with your vision.</p><br></br>
                                     <p>Whether you're looking to streamline internal workflows, enhance customer experiences, or drive innovation in your industry, our customized software development services can help you achieve your objectives. From web-based applications and mobile apps to enterprise software solutions and cloud-based platforms, 
                                        we have the expertise and experience to bring your ideas to life.</p><br></br>
                                        <p>At Lakshya, we believe in delivering solutions that not only meet your immediate needs but also position you for long-term success. That's why we focus on building robust, scalable, and future-proof software solutions that can adapt and grow 
                                            with your business.</p><br></br>
                                            <p>Partner with Lakshya for customized software development solutions that empower your business to thrive in today's digital world. Let us help you turn your ideas into reality and take your business to new 
                                                heights of success.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="new-release-hero-img-area">
            <div class="custom-container">
                <div class="new-release-img-box">
                    <img src="./assets/imgs/service-details-1.jpg" alt="Service Details"/>
                </div>
            </div>
        </section>


        
        <section class="about-service7-area">
            <div class="custom-container">
                <div class="about-service7-body d-flex align-items-center">
                    <div class="about-service7-content">
                        <div class="section-header">
                            <h5 class="section-subtitle"> CUSTOMIZED SOFTWARE DEVELOPMENT</h5>
                            <h1 class="section-title">Engine of progress</h1>
                            <p>Development as stone of innovation.</p>
                        </div>
                        <p>Software desolids to testing, and maintaining software as the applications and systems. It empowers us to turn ideas</p>
                        <p>Software developers, often rest referred to as coders or programmers, are sat the creative minds behind the digital innovations we use daily. They possess a unique blend of</p>
                        <p>Developers write lines of code by state using programming languages such as Python, Java, JavaScript, and C++, </p>
                    </div>
                    <div class="about-service7-img-box d-flex">
                        <img src="./assets/imgs/service-details-2.jpg" alt="Service Details"/>
                        <img src="./assets/imgs/service-details-3.jpg" alt="Service Details"/>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="about-service3-area about-service3-style-2">
            <img src="./assets/imgs/bg-shape-3.svg" alt="Shape" class="animation-slide-right bg-shape"/>
            <div class="custom-container">
                <div class="custom-row align-items-center">
                    <div class="img-box">
                        <img src="./assets/imgs/about-service-7.jpg" alt="About" />
                    </div>

                    <div class="content-box">
                        <h5 class="section-subtitle">IMPORTANCE</h5>
                        <h1 class="section-title">The Role of Developers</h1>
                        <p>In customized software development, developers play a central role in transforming a
                             client’s unique requirements into a functional, efficient, and scalable solution. 
                             They not only code the software but also collaborate with stakeholders, ensure quality, and provide ongoing support. 
                            Here’s a closer look at their roles and responsibilities:</p>
                        <ul>
                            <li>
                                <i class="las la-check"></i> Requirement Analysis and Gathering
                            </li>
                            <li>
                                <i class="las la-check"></i> System Design and Architecture
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="feature3-area feature3-style-2">
            
            <div class="custom-container">
                <div class="feature3-body">
                    <div class="section-header">
                        <h5 class="section-subtitle">FEATURES</h5>
                        <h1 class="section-title">Our range of customized software development services includes
                            </h1>
                    </div>

                    <div class="feature3-lists">
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Web Application Development</h3>
                            <p>This stage involves gathering and analyzing the client’s specific needs, defining objectives,
                                 and outlining project requirements. Clear and precise documentation
                                 is created to guide development and ensure alignment with the client’s vision. </p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Mobile App Development</h3>
                            <p>Designers create wireframes and mockups to visualize the interface, user experience, and functionalities of the software. This phase often includes prototyping to provide a working model for
                                 feedback and adjustments before full-scale development.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Enterprise Software Solutions</h3>
                            <p>The development team builds the software based on the requirements and design specifications. This stage includes backend and frontend development, integration of necessary features,
                                 and ensuring alignment with business processes.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Cloud-Based Platforms</h3>
                            <p>Rigorous testing is conducted to identify and fix bugs, ensure compatibility, and optimize performance. Quality assurance checks ensure that the software meets functional and non-functional requirements and works as 
                                intended across all devices and environments.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>E-commerce Solutions</h3>
                            <p>The finalized software is deployed into the client’s operational environment and integrated with existing systems as needed. 
                                This may also include user training to facilitate a smooth transition.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>CRM and ERP Systems: </h3>
                            <p>Custom software typically comes with continued support and maintenance,
                                 ensuring it remains functional, secure, and up-to-date as business needs evolve.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Internet Of Things</h3>
                            <p>Custom software typically comes with continued support and maintenance,
                                 ensuring it remains functional, secure, and up-to-date as business needs evolve.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        {/* <section class="testimonial-area">
            <div class="custom-container">
                
                <div class="testimonial-slider-wrap">
                    <div class="testimonial-slider swiper">
                        
                        <div class="swiper-wrapper">
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="animation-slide-right bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Platform helped me to increase my sales and revenue
                                        generated by Lakshya Software Technologies</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-1.jpg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Serana Belluci</h4>
                                            <p>Product Designer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Lakshya give the best service and top customer
                                        support. Really like to have more projects with you</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-2.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Megan Clarke</h4>
                                            <p>CEO, Sanlab</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>We’ve been using Lakshya for a few years, It is very 
                                        simple and easy to approach team and management</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-3.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Rachel Olsem</h4>
                                            <p>Full Stack Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    
                    
                    <div class="swiper-button-prev"><i class="las la-arrow-left"></i></div>
                    <div class="swiper-button-next"><i class="las la-arrow-right"></i></div>
                </div>

            </div>
        </section> */}
    </div>
   </Layout>
  
  )
}
