import React,{useEffect} from 'react';
import Layout from '../Layout';

export default function Cs() {
    useEffect(() => {
        // Scroll to the top of the page when the Contact component mounts
        window.scrollTo(0, 0);
    }, []);
  return (
    <Layout>
    <div  className='content1'>
    <section class="hero-service-wrap hero-section-wrap hero-overview-wrap">
            <div class="hero-section-content-wrap">
                <div class="custom-container">
                    <div class="hero-portfolio-body">
                        <div class="hero-section-content text-center">
                            <h5 class="section-subtitle">Cloud Services</h5>
                            <h1 class="section-title fade-in">Development is the pillar of
                                our company</h1>
                            <p>At Lakshya, we specialize in cloud solutions tailored to modern business needs. With expertise in Azure, Google Cloud, and AWS, we empower organizations to optimize operations, enhance scalability, and drive innovation. 
                                From migration to development, we deliver results aligned with your objectives.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="new-release-hero-img-area">
            <div class="custom-container">
                <div class="new-release-img-box">
                    <img src="./assets/imgs/service-details-1.jpg" alt="Service Details"/>
                </div>
            </div>
        </section>


        
        <section class="about-service7-area">
            <div class="custom-container">
                <div class="about-service7-body d-flex align-items-center">
                    <div class="about-service7-content">
                        <div class="section-header">
                            <h5 class="section-subtitle">SOFTWARE DEVELOPMENT</h5>
                            <h1 class="section-title">Engine of progress</h1>
                            <p>Development as stone of innovation.</p>
                        </div>
                        <p>Software development is the stab process of designing, programming, solids to testing, and maintaining software as the applications and systems. It empowers us to turn ideas</p>
                        <p>Software developers, often rest referred to as coders or programmers, are sat the creative minds behind the digital innovations we use daily. They possess a unique blend of</p>
                        <p>Developers write lines of code by state using programming languages such as Python, Java, JavaScript, and C++, </p>
                    </div>
                    <div class="about-service7-img-box d-flex">
                        <img src="./assets/imgs/service-details-2.jpg" alt="Service Details"/>
                        <img src="./assets/imgs/service-details-3.jpg" alt="Service Details"/>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="about-service3-area about-service3-style-2">
            <img src="./assets/imgs/bg-shape-3.svg" alt="Shape" class="animation-slide-right bg-shape"/>
            <div class="custom-container">
                <div class="custom-row align-items-center">
                    <div class="img-box">
                        <img src="./assets/imgs/about-service-7.jpg" alt="About" />
                    </div>

                    <div class="content-box">
                        <h5 class="section-subtitle">IMPORTANCE</h5>
                        <h1 class="section-title">The Role of Developers</h1>
                        <p>Software developers, often referred to as coders or programmers, are the creative minds behind the digital innovations we use daily.</p>
                        <ul>
                            <li>
                                <i class="las la-check"></i> PPD Development
                            </li>
                            <li>
                                <i class="las la-check"></i> Easy to Use
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


        
        <section class="feature3-area feature3-style-2">
            
            <div class="custom-container">
                <div class="feature3-body">
                    <div class="section-header">
                        <h5 class="section-subtitle">FEATURES</h5>
                        <h1 class="section-title">Key Stages in Cloud Services
                            </h1>
                    </div>

                    <div class="feature3-lists">
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Azure Solutions</h3>
                            <p>Leverage the power of Microsoft Azure with Lakshya's comprehensive suite of Azure services. From cloud migration and deployment to application development and management, we offer end-to-end solutions tailored to your business needs. Whether you're looking to optimize infrastructure, enhance security, 
                                or scale your operations, our Azure experts have the expertise to drive success. </p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Azure migration</h3>
                            <p>Seamlessly migrate your on-premises infrastructure or existing applications to the Azure cloud, 
                                minimizing downtime and ensuring a smooth transition.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Azure development</h3>
                            <p>Develop scalable and secure applications on the Azure platform, leveraging its extensive set of tools and services for rapid innovation and deployment.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Azure management</h3>
                            <p>Optimize and manage your Azure resources efficiently with Lakshya's Azure management services, ensuring cost-effectiveness and performance optimization.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Azure security</h3>
                            <p>Enhance the security of your Azure environment with robust security solutions and best practices, safeguarding your data and applications from threats and vulnerabilities.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>Google cloud solutions</h3>
                            <p>Unlock the full potential of Google Cloud Platform (GCP) with Lakshya's comprehensive range of Google Cloud services. Whether you're looking to modernize your infrastructure, harness data analytics, 
                                or build AI-powered applications, our Google Cloud experts can help you achieve your goals.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>GCP migration</h3>
                            <p>Seamlessly migrate your workloads to the Google Cloud Platform, leveraging its global infrastructure and advanced migration tools for a smooth transition.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>GCP data analytics</h3>
                            <p>Harness the power of Google Cloud's data analytics and machine learning capabilities to derive actionable insights and drive business growth.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>GCP application development</h3>
                            <p>Develop and deploy scalable and reliable applications on Google Cloud Platform, leveraging its flexible and innovative development tools.</p> </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>GCP Infrastructure Optimization</h3>
                            <p>Optimize your infrastructure on Google Cloud Platform for performance, scalability, and cost-efficiency, maximizing the value of your cloud investment.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>AWS Solutions</h3>
                            <p>Maximize the potential of Amazon Web Services (AWS) with Lakshya's comprehensive suite of AWS services. Whether you're looking to build scalable applications, optimize infrastructure, or implement cloud security solutions,
                                 our AWS experts can help you achieve your business objectives.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>AWS migration</h3>
                            <p>Seamlessly migrate your applications and data to the AWS cloud, leveraging AWS's migration services and best practices for a successful migration.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>AWS application development</h3>
                            <p>Develop and deploy scalable and resilient applications on AWS, leveraging its extensive set of development tools and services</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>AWS Infrastructure Optimization</h3>
                            <p>Optimize your infrastructure on AWS for performance, reliability, and cost-efficiency, ensuring optimal resource utilization and scalability.</p>
                        </div>
                        <div class="feature-box">
                            <i class="icon-box las la-check"></i>
                            <h3>AWS Security</h3>
                            <p>Enhance the security of your AWS environment with robust security solutions and best practices, protecting your data and applications from threats and vulnerabilities.</p>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>




        <section class="testimonial-area">
            <div class="custom-container">
                
                <div class="testimonial-slider-wrap">
                    <div class="testimonial-slider swiper">
                        
                        <div class="swiper-wrapper">
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="animation-slide-right bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Platform helped me to increase my sales and revenue
                                        generated by Lakshya Software Technologies</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-1.jpg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Serana Belluci</h4>
                                            <p>Product Designer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>Lakshya give the best service and top customer
                                        support. Really like to have more projects with you</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-2.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Megan Clarke</h4>
                                            <p>CEO, Sanlab</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide testimonial-item">
                                <div class="testimonial-item-body">
                                    <img class="bg-shape" src="./assets/imgs/bg-shape-3.svg" alt="Shape"/>
                                    <span class="platform-name">
                                        <img src="./assets/imgs/icon-linkedin.svg" alt="Icon"/>
                                    </span>
                                    <h1>We’ve been using Lakshya for a few years, It is very 
                                        simple and easy to approach team and management</h1>
                                    <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                                    <div class="author-box d-flex align-items-center">
                                        <img src="./assets/imgs/testimonial-3.jpeg" alt="Testimonial"/>
                                        <div class="author-box-content">
                                            <h4>Rachel Olsem</h4>
                                            <p>Full Stack Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    
                    
                    <div class="swiper-button-prev"><i class="las la-arrow-left"></i></div>
                    <div class="swiper-button-next"><i class="las la-arrow-right"></i></div>
                </div>

            </div>
        </section>
    </div>
  </Layout>

  )
}
